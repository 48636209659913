export const breadcrumbList = [
  {
    name: '目标管理',
    path: ''
  },
  {
    name: '提成统计',
    path: ''
  }
]

export const breadcrumbList2 = [
  {
    name: '目标管理',
    path: ''
  },
  {
    name: '业绩统计',
    path: ''
  }
]

export const breadcrumbList3 = [
  {
    name: '目标管理',
    path: ''
  },
  {
    name: '无规则提成统计',
    path: ''
  }
]

export const breadcrumbList4 = [
  {
    name: '目标管理',
    path: ''
  },
  {
    name: '总规则提成统计',
    path: ''
  }
]

export const contentTableConfig = {
  propList: [
    {
      prop: 'tichengQty',
      label: '提成金额',
      minWidth: '150',
      slotName: 'tichengQty'
    },
    { prop: 'typeStr', label: '类型', minWidth: '150' },
    { prop: 'kindStr', label: '种类', minWidth: '150' },
    {
      prop: 'userRealname',
      label: '理疗师',
      minWidth: '100',
      slotName: 'userRealname'
    },
    {
      prop: 'shopName',
      label: '门店',
      minWidth: '100',
      slotName: 'shopName'
    },
    {
      prop: 'customerName',
      label: '客户',
      slotName: 'customerName'
    },
    {
      prop: 'createTime',
      label: '时间',
      minWidth: '120',
      slotName: 'createTime'
    },
    {
      prop: 'items',
      label: '相关产品',
      minWidth: '120',
      slotName: 'items'
    },
    {
      prop: 'orderNo',
      label: '关联订单号',
      minWidth: '120',
      slotName: 'orderNo'
    }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}

export const contentTableConfig2 = {
  propList: [
    { prop: 'yejiQty', label: '业绩', minWidth: '150', slotName: 'yejiQty' },
    { prop: 'typeStr', label: '类型', minWidth: '150' },
    { prop: 'kindStr', label: '种类', minWidth: '150' },
    {
      prop: 'userRealname',
      label: '理疗师',
      minWidth: '100',
      slotName: 'userRealname'
    },
    {
      prop: 'shopName',
      label: '门店',
      minWidth: '100',
      slotName: 'shopName'
    },
    {
      prop: 'customerName',
      label: '客户',
      slotName: 'customerName'
    },
    {
      prop: 'createTime',
      label: '时间',
      minWidth: '120',
      slotName: 'createTime'
    },
    {
      prop: 'items',
      label: '销售产品',
      minWidth: '120',
      slotName: 'items'
    },
    {
      prop: 'orderNo',
      label: '关联订单号',
      minWidth: '120',
      slotName: 'orderNo'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}

export const contentTableConfig3 = {
  propList: [
    {
      prop: 'userRealname',
      label: '理疗师',
      minWidth: '100',
      slotName: 'userRealname'
    },
    // {
    //   prop: 'shopName',
    //   label: '门店',
    //   minWidth: '100',
    //   slotName: 'shopName'
    // },
    {
      prop: 'serveyeji',
      label: '服务业绩',
      slotName: 'serveyeji'
    },
    {
      prop: 'serveqty',
      label: '服务提成',
      slotName: 'serveqty'
    },
    {
      prop: 'saleyeji',
      label: '销售业绩',
      minWidth: '120',
      slotName: 'saleyeji'
    },
    {
      prop: 'saleqty',
      label: '销售提成',
      minWidth: '120',
      slotName: 'saleqty'
    },
    {
      prop: 'lastupdate',
      label: '最后更新时间',
      minWidth: '120',
      slotName: 'lastupdate'
    }
    // {
    //   prop: 'orderNo',
    //   label: '关联订单号',
    //   minWidth: '120',
    //   slotName: 'orderNo'
    // },
    // { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: false
}
