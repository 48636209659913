<template>
  <div class="ticheng">
    <breadcrumb :breadcrumbList="breadcrumbList3"></breadcrumb>
    <div class="main-content">
      <list></list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import topContent from '../base-ui/top-content/top-content.vue'
import list from '../base-ui/list3/list.vue'
import { breadcrumbList3 } from '../config'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'

export default defineComponent({
  props: {

  },
  components: {
    topContent,
    list,
    Breadcrumb
  },
  setup() {


    return {
      breadcrumbList3
    }

  }
})
</script>

<style scoped lang="less">
.ticheng {
  min-width: 1080px;

  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>
