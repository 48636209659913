<template>
  <div class="top-content">
    <el-date-picker v-model="value1" type="daterange" range-separator="到" start-placeholder="开始时间"
      end-placeholder="结束时间" />
    <el-select v-model="value" class="m-2" placeholder="请选择门店" size="large">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-select v-model="value" class="m-2" placeholder="请选择理疗师" size="large">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-select v-model="value" class="m-2" placeholder="业绩类型" size="large">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <el-button type="primary">查询</el-button>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  &:deep(.el-select){
    margin-left: 10px;
  }
  &:deep(.el-button){
    margin-left: 10px;
  }
}
</style>




